import React from 'react'
import VpnCardCercle from '../../../Icons/svg/VpnCardCercle'
import style from './FirstBlockCard.module.css'
import star_gif from './star.gif'
import VpnCardCercleNomis from "../../../Icons/svg/VpnCardCercleNomis";

export const FirstBlockCard = (props) => {
    // console.log(props.props.sale_amount)
    return (
        <>
            <div className={style.vpn_card__main__fist_block}>
                <img
                    style={{width: "100%"}}
                    height={181}
                    src={props.nomis ? "/images/nomis_plans_img/nomis_gradient.png" : "/images/deguard_gradinet.png"}
                    className={style.vpn_card__bg}
                    alt="gradient"
                />

                <div className={style.vpn_card__main__fist_block__img_holder}>
                    <img width="110" height="120" src={props.props.img_src} alt="plan_image"/>
                </div>

                <div className={style.vpn_card__main__fist_block__price}>
                    {props.props.sale_amount !== 0 && <div className={style.vpn_card__main__fist_block__price__sale}>
                        <p>-{props.props.sale_amount}%</p>
                    </div>}
                    <div className={style.vpn_card__main__fist_block__price__text}>
                        <h1 className="plan_name_h1">{props.props.plan_name}</h1>
                        <p className="plan_price">
                            {/*<img src={star_gif} alt="plan_image" width={50} height={50}/>*/}
                            <p>$</p>
                            <span>{props.props.usd}</span>
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}
