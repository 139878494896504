import axios from "axios";
import PaymnetStore from "../../../store/paymnet-store";
import UserStore from "../../../store/user-store";

const apiUrl = process.env.REACT_APP_API_URL;
const TG_WEB_APP = window.Telegram.WebApp


export const getLavaTopInvoice = async (planId, paymentMethod, langCode) => {
    const body = {
        "user_id": UserStore.userId,
        "plan_id": planId,
        "payment_method": paymentMethod,
        "lang_code": langCode === 'ru' ? 'ru' : 'en',
        "email": PaymnetStore.email
    }
    if (UserStore.partnerId) {
        body["partner_id"] =UserStore.partnerId
    }
    axios.post(`${apiUrl}/v2/payment/lava_top_invoice/`, {
        ...body
    }, {
        headers: {
            Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`
        }
    }).then(data => {
        if (data.status === 200) {
            PaymnetStore.setPaymentUrl(data.data?.payment_url)
            PaymnetStore.setTxId(data.data?.tx_id)
            PaymnetStore.setIsPaymentUrlOpened(true)
            TG_WEB_APP.openLink(data.data?.payment_url)

        }
    })
}