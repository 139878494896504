import {makeAutoObservable} from "mobx";

class UserStore {
    userId = null;
    partnerId = null;
    langCode = '';
    userPlanData = null;
    servers_ping = null
    constructor() {
        makeAutoObservable(this)
    }

    setUserId(id) {
        this.userId = id;
    }

    setLangCode(langCode) {
        this.langCode = langCode
    }

    setPartner(partnerId) {
        this.partnerId = partnerId;
    }

    setUserPlanData(planData) {
        if (planData) {
            this.userPlanData = {
                ...planData
            };
        } else {
            this.userPlanData = null;
        }
    }

    setServersPing(data) {
        this.servers_ping = {
            ...data
        }
    }

}

export default new UserStore();