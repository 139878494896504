import styles from './EmailInput.module.css';
import {useEffect, useRef, useState} from "react";
import PaymnetStore from "../../../store/paymnet-store";
import {observer} from "mobx-react-lite";
import UserStore from "../../../store/user-store";

export const EmailInput = observer(() => {
        const [isValid, setIsValid] = useState(true);
        const inputRef = useRef(null);
        const wrapperRef = useRef(null);
        const [isKeyboardVisible, setKeyboardVisible] = useState(false);


        const handleChange = e => {
            const email = e.target.value.replace(/[\sА-Яа-яЁё]/g, "");
            PaymnetStore.setEmail(email);
            // console.log(PaymnetStore.email)
            setIsValid(validateEmail(email));
        };

        // console.log(PaymnetStore.email)
        const handleKeyDown = e => {
            if (e.key === "Enter") {
                if (isValid) {
                    inputRef.current.blur()
                    PaymnetStore.setIsValidEmail(true)
                    window.scrollTo({
                        top: document.documentElement.scrollHeight,
                        behavior: 'smooth'
                    });
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth'
                    });

                }
            }
        }

        const handleFocus = () => {
            wrapperRef.current.style.marginBottom = "50px"

            setTimeout(() => {
                inputRef.current.scrollIntoView({behavior: "smooth", block: "start"});
                // inputRef.current.scrollBotom = inputRef.current.scrollHeight;
            }, 200)


            setKeyboardVisible(true)

        };

        const handleBlur = () => {
            wrapperRef.current.style.marginBottom = "0"
            window.scrollTo({
                top: document.documentElement.scrollHeight, // высота всего документа
                behavior: 'smooth' // делает прокрутку плавной
            });
            window.scrollTo({
                top: 0,
                behavior: 'smooth' // Плавная прокрутка
            });

        }
        useEffect(() => {
            const handleResize = () => {
                if (window.innerHeight < window.screen.height * 0.7) {
                    setKeyboardVisible(true);  // Предполагаем, что клавиатура открыта
                } else {
                    setKeyboardVisible(false); // Клавиатура закрыта
                }
            };

            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }, []);

        const validateEmail = (email) => {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (emailRegex.test(email)) {
                PaymnetStore.setIsValidEmail(true)
                PaymnetStore.setEmail(email.trim())
                inputRef.current.style.borderColor = 'green'
            } else PaymnetStore.setIsValidEmail(false)
            return emailRegex.test(email)
        }

        return (
            <div ref={wrapperRef} className={styles.wrapper}>
                <p id="input_heading">{UserStore.langCode === "ru" ? "Email для получения квитанции об оплате" : "Email for payment receipt"}</p>
                <input
                    lang="en"
                    disabled={PaymnetStore.isPaymentProcessed}
                    placeholder={UserStore.langCode === "ru" ? "Введите email": "Enter email"}
                    type="email"
                    onFocus={handleFocus}
                    ref={inputRef}
                    value={PaymnetStore.email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    style={{borderColor: isValid ? 'initial' : 'red', fontSize: '15px'}}

                />
            </div>
        )
    }
)