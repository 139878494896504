import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import style from './Protected.module.css'
import ProtectLogoHome from '../Icons/svg/ProtectLogoHome'
import moment from 'moment';
import UserStore from "../../store/user-store";


export const Protected = ({props}) => {
    const date = moment(props.user_plan_end).format('DD.MM.YYYY');
    const lang = UserStore.langCode
    return (
        <div className={style.card}>
            <div className={style.card__content}>
                <div className={style.card__content__text}>
                    <div className={style.protect_status}></div>
                    {lang === "ru" ? <h1>Вы защищены</h1>: <h1>You are protected</h1>}
                </div>
                <p>{lang === "ru" ? "Подписка истекает:" :"Plan is expiring:"} <span style={{color: "white"}}>{date}</span></p>

            </div>
            <Link className={style.card__btn} to='/vpn'>{lang === "ru" ? "Перейти к VPN" : "Go to VPN"}</Link>
            <svg className={style.card__logo} width="81" height="104" viewBox="0 0 81 104" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <g id="DeGuard-logo" clipPath="url(#clip0_384_7916)">
                    <path id="Vector" fillRule="evenodd" clipRule="evenodd"
                          d="M31.2135 8.99285C26.0355 11.3229 20.6798 13.2317 15.1983 14.7002L13.3838 15.1616V29.8368C17.1796 28.515 20.9251 27.0585 24.6125 25.4701C32.4581 22.1966 40.0348 18.3538 47.276 13.9751C54.5356 18.3691 62.1322 22.2239 70.001 25.5063C73.653 27.0723 77.3616 28.509 81.1193 29.8136V15.1616L79.3051 14.7002C73.8156 13.2406 68.4524 11.3402 63.2658 9.01708C58.1729 6.79868 53.2552 4.19244 48.5581 1.22118L47.2515 0.371094L45.9209 1.19663C41.2237 4.1682 36.3069 6.77444 31.2135 8.99285Z"
                          fill="white"/>
                    <path id="Vector_2"
                          d="M24.6098 25.4677C16.6515 28.896 8.42222 31.7093 0 33.8809V60.2234C0 82.317 11.544 97.5667 23.4251 107.444C29.3704 112.384 35.3462 115.927 39.8652 118.232C42.1209 119.383 43.9993 120.217 45.3088 120.757C45.9138 121.006 46.3894 121.19 46.7191 121.311H46.7396L47.3175 121.506C48.7156 120.989 59.9935 116.662 71.2052 107.361C83.0924 97.501 94.6409 82.2953 94.6409 60.2234V33.8818C86.209 31.723 77.9687 28.9218 69.9983 25.5038C62.1295 22.2215 54.5328 18.3666 47.2733 13.9727C40.0321 18.3513 32.4554 22.1942 24.6098 25.4677Z"
                          fill="url(#paint0_linear_384_7916)"/>
                </g>
                <defs>
                    <linearGradient id="paint0_linear_384_7916" x1="58.2295" y1="121.467" x2="18.8911" y2="14.0523"
                                    gradientUnits="userSpaceOnUse">
                        <stop stopColor="#F44E17"/>
                        <stop offset="1" stopColor="#FFAC00"/>
                    </linearGradient>
                    <clipPath id="clip0_384_7916">
                        <rect width="95" height="122" fill="white"/>
                    </clipPath>
                </defs>
            </svg>

        </div>
    )
}
