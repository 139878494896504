import axios from "axios";
import {useQuery} from "@tanstack/react-query";
import UserStore from "../../store/user-store";

const apiUrl = process.env.REACT_APP_API_URL;

const fetchPartners = async () => {
    return axios.get(`${apiUrl}/v2/miniapp/get_partners/`, {
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`
        }
    });
}


export const usePartnerList = (isTgStartParams) => {
    const {data, isLoading} = useQuery(
        {
            queryKey: ['partners'],
            queryFn: () => fetchPartners(),
            select: (data) => data.data,
            enabled: Boolean(!UserStore.partnerId && isTgStartParams)
        }
    );
    return {data, isLoading}
}