import UserStore from "../../store/user-store";
import style from "./VpnGuide.module.css";
import {options} from "axios";
export const VpnGuide = () => {
    const TG_WEB_APP = window.Telegram.WebApp

    return (
        <div className={style.guide}>
            <div className={style.text}>
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="Icon">
                        <path id="Vector"
                              d="M16 3C13.4288 3 10.9154 3.76244 8.77759 5.1909C6.63975 6.61935 4.97351 8.64968 3.98957 11.0251C3.00563 13.4006 2.74819 16.0144 3.2498 18.5362C3.75141 21.0579 4.98953 23.3743 6.80762 25.1924C8.6257 27.0105 10.9421 28.2486 13.4638 28.7502C15.9856 29.2518 18.5995 28.9944 20.9749 28.0104C23.3503 27.0265 25.3807 25.3603 26.8091 23.2224C28.2376 21.0846 29 18.5712 29 16C28.9964 12.5533 27.6256 9.24882 25.1884 6.81163C22.7512 4.37445 19.4467 3.00364 16 3ZM16 24C15.7033 24 15.4133 23.912 15.1667 23.7472C14.92 23.5824 14.7277 23.3481 14.6142 23.074C14.5007 22.7999 14.471 22.4983 14.5288 22.2074C14.5867 21.9164 14.7296 21.6491 14.9393 21.4393C15.1491 21.2296 15.4164 21.0867 15.7074 21.0288C15.9983 20.9709 16.2999 21.0007 16.574 21.1142C16.8481 21.2277 17.0824 21.42 17.2472 21.6666C17.412 21.9133 17.5 22.2033 17.5 22.5C17.5 22.8978 17.342 23.2794 17.0607 23.5607C16.7794 23.842 16.3978 24 16 24ZM17 17.91V18C17 18.2652 16.8946 18.5196 16.7071 18.7071C16.5196 18.8946 16.2652 19 16 19C15.7348 19 15.4804 18.8946 15.2929 18.7071C15.1054 18.5196 15 18.2652 15 18V17C15 16.7348 15.1054 16.4804 15.2929 16.2929C15.4804 16.1054 15.7348 16 16 16C17.6538 16 19 14.875 19 13.5C19 12.125 17.6538 11 16 11C14.3463 11 13 12.125 13 13.5V14C13 14.2652 12.8946 14.5196 12.7071 14.7071C12.5196 14.8946 12.2652 15 12 15C11.7348 15 11.4804 14.8946 11.2929 14.7071C11.1054 14.5196 11 14.2652 11 14V13.5C11 11.0188 13.2425 9 16 9C18.7575 9 21 11.0188 21 13.5C21 15.6725 19.28 17.4913 17 17.91Z"
                              fill="white"/>
                    </g>
                </svg>

                <p>{UserStore.langCode === 'ru' ? 'Как пользоваться?' : 'How to use it?'}</p>
            </div>
            <button className={style.btn} onClick={() => {
                TG_WEB_APP.openLink('https://docs.deguard.io/',true)
            }}>{UserStore.langCode === 'ru' ? 'Инструкция' : 'Open guide'}</button>
        </div>
    )
}
