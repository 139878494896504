import style from './Payments.module.css'
import {useEffect, useState} from "react";
import PaymnetStore from "../../store/paymnet-store";
import {observer} from "mobx-react-lite";
import {usePaymentStatus} from "../../Hooks/Api/Payments/fetchPaymnetStatus";
import {getUserPlans} from "../../Hooks/Api/Plans/useGetPlans";
import {payPalSvg, unlinmitSvg} from "../Icons/svg/paymnetSvgs";
import UserStore from "../../store/user-store";

export const Payments = observer(({paymentsItem}) => {
    const [paymentMethod, setPaymentMethod] = useState();
    const [txActive, setTxActive] = useState(false);

    const lang_code = UserStore.langCode
    const {data, isLoading, error, refetch} = usePaymentStatus(PaymnetStore.isPaymentUrlOpened)

    useEffect(() => {
        if (data?.tx_status === 'subscription-active' || data?.tx_status === "completed") {
            PaymnetStore.setPaymentStatus(data?.tx_status)
            PaymnetStore.setIsPaymentUrlOpened(false)
            getUserPlans()
            PaymnetStore.setPaymentPush(true)
        } else if (['failed', 'cancelled', 'subscription-cancelled', 'subscription-failed'].includes(data?.tx_status)) {
            PaymnetStore.setPaymentStatus(data?.tx_status)
            PaymnetStore.setIsPaymentUrlOpened(false)
            PaymnetStore.setPaymentProcessed(false)
            PaymnetStore.setTxId(null)
            PaymnetStore.setPaymentUrl(null)
            PaymnetStore.resetTimer()
        }
    }, [data])


    // TODO Оотрпавлять longpolling после получниея tx_id из запроса на invoice
    return (
        <div className={style.payments}>
            <div className={style.heading}>
                <p>{lang_code === 'ru' ? "Способы оплаты" : "Select payment method"}</p>
                <button className={style.heading_close_btn} onClick={() => {
                    PaymnetStore.setIsPaymentSelected(false)
                    PaymnetStore.clearPaymentMethod()
                    PaymnetStore.setPaymentProcessed(false)
                    PaymnetStore.resetTimer()
                }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M9.40156 1.63588L1.63686 9.40059L0.601562 8.36529L8.36627 0.600586L9.40156 1.63588Z"
                              fill="white"/>
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M8.36627 9.40059L0.601563 1.63588L1.63686 0.600586L9.40156 8.36529L8.36627 9.40059Z"
                              fill="white"/>
                    </svg>
                </button>
            </div>
            <div className={style.payments_methods}>

                {paymentsItem.map((el, index) =>
                    <div key={index}
                         className={
                             PaymnetStore.paymentMethod - 1 === index ?
                                 `${style.payment_item} ${style.active_payment_item}`
                                 : el.isSoon ?
                                     `${style.payment_item} ${style.payment_item_soon}` : style.payment_item}
                         id={String(index)}
                         onClick={() => {
                             setPaymentMethod(index)
                             PaymnetStore.setPaymentMethod(el.id)
                             setTimeout(() => {
                                 document.querySelector('.vpn__button_buy_plan_btn').scrollIntoView({behavior: "smooth"});
                             }, 10)
                         }}>
                        <div className={style.payments_name}>
                            {el.logo}
                            <p>{el.name}</p>
                        </div>
                        <p>
                            {el.isSoon ? (
                                <span style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    gap: "5px"
                                }}>
                                    <span>Soon</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="15" viewBox="0 0 10 15"
                                         fill="none">
                                    <path
                                        d="M1.28585 14.7476H8.64607C9.45968 14.7476 9.92676 14.3032 9.92676 13.4745V13.2635C9.93429 11.1542 7.66671 9.45159 6.74763 8.57017C6.46889 8.3065 6.31822 8.06543 6.31822 7.70382C6.31822 7.34222 6.46889 7.11621 6.74763 6.83747C7.66671 5.94099 9.92676 4.32882 9.92676 2.15165V1.94824C9.92676 1.12709 9.45968 0.682617 8.64607 0.682617H1.28585C0.494838 0.682617 0.0654297 1.12709 0.0654297 1.88797V2.15165C0.0654297 4.32882 2.33301 5.94852 3.24456 6.83747C3.53083 7.11621 3.67397 7.34222 3.67397 7.70382C3.67397 8.06543 3.5233 8.3065 3.24456 8.57017C2.33301 9.45159 0.0654297 11.1542 0.0654297 13.2635V13.5347C0.0654297 14.3032 0.494838 14.7476 1.28585 14.7476ZM1.61733 2.96526H8.36733C8.6536 2.96526 8.70633 3.19127 8.5406 3.50767C7.87765 4.71303 5.60254 6.65667 4.99233 6.65667C4.38211 6.65667 2.1296 4.71303 1.45159 3.50767C1.27832 3.1988 1.33105 2.96526 1.61733 2.96526Z"
                                        fill="white" fillOpacity="0.7"
                                    />
                                  </svg>
                                </span>
                            ) : (
                                el.amount
                            )}
                        </p>

                        {
                            paymentMethod === index &&
                            <svg className={style.active_payment_item_check} xmlns="http://www.w3.org/2000/svg"
                                 width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0ZM11.1107 17.4719L18.4 8.1L16.9 6.93333L10.8873 14.664L6.8966 10.9393L5.6 12.3286L11.1107 17.4719Z"
                                      fill="white"/>
                            </svg>
                        }
                    </div>)
                }
            </div>
        </div>
    )

})
