// const createPlan = () => fetch(`${apiUrl}/plans/create`, {
//                 method: 'POST', headers: {
//                     'Content-Type': 'application/json', Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
//                 }, body: JSON.stringify(body)
import axios from "axios";
import PaymnetStore from "../../store/paymnet-store";
import UserStore from "../../store/user-store";

const apiUrl = process.env.REACT_APP_API_URL;

export const createPlan = async (planId, userId, partnerId, paymentMethod) => {
    const data = {user_id: userId, user_plan: planId, payment_method: paymentMethod, from_tg_bot: true}
    if (partnerId) {
        data.from_partner = partnerId;
    }
    axios.post(`${apiUrl}/plans/create`, data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`
            }
        }
    ).then(
        data => {
            if (data.status === 200) {
                UserStore.setUserPlanData(data.data)
                PaymnetStore.setPaymentPush(true)
            }

        }
    )
}