import axios from "axios";
import UserStore from "../../../store/user-store";
import userStore from "../../../store/user-store";
import PaymnetStore from "../../../store/paymnet-store";
const apiUrl = process.env.REACT_APP_API_URL;

export const getUserPlans = async () => {
    axios.get(`${apiUrl}/plans/${UserStore.userId}`, {
        headers: {
            Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
        }
    }).then(data => {
        if (data.status === 200) {
            userStore.setUserPlanData(data.data)
            PaymnetStore.setIsPaymentUrlOpened(false)
        }
    });
}