import styles from "./PaymentStatus.module.css";
import PaymnetStore from "../../../store/paymnet-store";
import UserStore from "../../../store/user-store";
import React from "react";

export const PaymentStatus = () => {
    const plan_mapping = {
        ru: {
            planName: {
                1: "Месячная подписка",
                3: "Годовая подиска",
                6: "Private Pass"
            },
            paymentType: {
                1: {
                    name: 'Российская карта',
                    logo: <svg width="40" height="40" viewBox="0 0 40 40" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
                        <rect width="40" height="40" rx="20" fill="black"/>
                        <path
                            d="M15.9997 30.3346V11.668H23.9997C25.0605 11.668 26.078 12.0894 26.8281 12.8395C27.5782 13.5897 27.9997 14.6071 27.9997 15.668C27.9997 16.7288 27.5782 17.7463 26.8281 18.4964C26.078 19.2465 25.0605 19.668 23.9997 19.668H13.333"
                            stroke="white" strokeWidth="2.66667" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M23.9997 25H13.333" stroke="white" strokeWidth="2.66667" strokeLinecap="round"
                              strokeLinejoin="round"/>
                    </svg>,
                    amount: '9,200 ₽'
                },
                2: {
                    name: 'Иностранная карта',
                    logo: <svg width="40" height="40" viewBox="0 0 40 40" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M20 40C31.0457 40 40 31.0457 40 20C40 8.95429 31.0457 0 20 0C8.95429 0 0 8.95429 0 20C0 31.0457 8.95429 40 20 40Z"
                            fill="white"/>
                        <g clipPath="url(#clip0_1428_39705)">
                            <path d="M22.7155 14.5352H16.4434V25.8037H22.7155V14.5352Z" fill="#FF5A00"/>
                            <path
                                d="M16.8608 20.1675C16.8608 17.8781 17.9371 15.8461 19.5887 14.5332C18.3732 13.5776 16.8399 13 15.1675 13C11.2057 13 8 16.2057 8 20.1675C8 24.1294 11.2057 27.3351 15.1675 27.3351C16.8399 27.3351 18.3732 26.7575 19.5887 25.8018C17.9348 24.5075 16.8608 22.457 16.8608 20.1675Z"
                                fill="#EB001B"/>
                            <path
                                d="M31.1785 20.1675C31.1785 24.1294 27.9728 27.3351 24.011 27.3351C22.3386 27.3351 20.8053 26.7575 19.5898 25.8018C21.2623 24.4866 22.3177 22.457 22.3177 20.1675C22.3177 17.8781 21.2414 15.8461 19.5898 14.5332C20.803 13.5776 22.3362 13 24.0087 13C27.9728 13 31.1785 16.2265 31.1785 20.1675Z"
                                fill="#F79E1B"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_1428_39705">
                                <rect width="23.1773" height="18" fill="white" transform="translate(8 11)"/>
                            </clipPath>
                        </defs>
                    </svg>,
                    amount: '$99'
                }
            }

        },
        en: {
            planName: {
                1: "Monthly subscriptions",
                3: "Annual subscription",
                6: "Private Pass"
            }
        }
    }

    const TG_WEB_APP = window.Telegram.WebApp
    const lang_code = UserStore.langCode

    return (

        <>
            {PaymnetStore.paymentStatus !== 'in-progress' &&
                <div className={styles.payment_status}>
                    <div className={styles.payment_status_content}>

                        {
                            ['subscription-active', 'completed'].includes(PaymnetStore.paymentStatus) ?
                                (
                                    <>


                                        <div className={styles.status_content}>
                                            <svg style={{marginBottom: '10px'}} xmlns="http://www.w3.org/2000/svg" width="111" height="112"
                                                 viewBox="0 0 111 112"
                                                 fill="none">
                                                <rect y="0.5" width="111" height="111" rx="55.5" fill="#3EB350"/>
                                                <path fillRule="evenodd" clipRule="evenodd"
                                                      d="M80.7273 41.6783L51.9947 78.6201L30.2727 58.3462L35.3836 52.8703L51.1139 67.5519L74.8147 37.0796L80.7273 41.6783Z"
                                                      fill="white"/>
                                            </svg>
                                            <p style={{
                                                fontFamily: "VK Sans Display",
                                                fontSize: "16px",
                                                opacity: "0.5",
                                            }}>{lang_code === "ru" ? "Успешная оплата" : "Payment successful"}</p>
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            alignItems: "center",
                                            flexDirection: "column",
                                            gap: "10px",
                                            width: '100%'
                                        }}>
                                            <button style={{color: 'black'}} onClick={() => {
                                                PaymnetStore.setPaymentStatus(null)
                                            }}>{UserStore.langCode === 'ru' ? 'Перейти к VPN' : 'Go to VPN'}</button>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className={styles.status_content}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="203" height="113"
                                                 viewBox="0 0 203 113"
                                                 fill="none">
                                                <g clipPath="url(#clip0_5_60)">
                                                    <path
                                                        d="M149.687 45.6262L125.222 89.3581C123.923 91.6184 123.188 94.3304 123.188 96.8728C123.188 99.6398 123.866 102.223 125.093 104.444H52.279C40.4703 104.444 34.5377 98.6244 34.5377 86.985V43.5357H151.08C150.564 44.1781 150.099 44.8778 149.687 45.6262ZM57.6467 65.6275C54.3132 65.6275 52.1093 67.8314 52.1093 71.0516V81.5044C52.1093 84.6683 54.3132 86.9286 57.6467 86.9286H71.4895C74.8231 86.9286 77.0265 84.6683 77.0265 81.5044V71.0516C77.0265 67.8314 74.8231 65.6275 71.4895 65.6275H57.6467ZM167.315 25.4553V30.7099H34.5377V25.4553C34.5377 13.8161 40.4703 7.94 52.279 7.94H149.574C161.439 7.94 167.315 13.8161 167.315 25.4553Z"
                                                        fill="white"/>
                                                    <path
                                                        d="M169.575 49.3552L194.097 93.087C194.775 94.3304 195.114 95.5734 195.114 96.8727C195.114 101.11 192.119 104.444 187.486 104.444H138.5C133.867 104.444 130.929 101.11 130.929 96.8727C130.929 95.5734 131.268 94.3304 131.945 93.1439L156.411 49.3552C157.823 46.7563 160.422 45.5132 163.021 45.5132C165.564 45.5132 168.106 46.7563 169.575 49.3552ZM158.219 91.0533C158.219 93.7084 160.422 95.8559 163.021 95.8559C165.677 95.8559 167.767 93.7084 167.767 91.0533C167.824 88.5105 165.677 86.3071 163.021 86.3071C160.422 86.3071 158.219 88.454 158.219 91.0533ZM159.123 62.9718L159.631 79.0749C159.687 80.9958 161.044 82.352 163.021 82.352C164.999 82.352 166.411 80.9958 166.468 79.0749L166.92 62.9718C166.976 60.6556 165.394 59.0737 163.021 59.0737C160.648 59.0737 159.066 60.6556 159.123 62.9718Z"
                                                        fill="#FF7C7C"/>
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_5_60">
                                                        <rect width="202.105" height="112.042" fill="white"
                                                              transform="translate(0.75 0.142822)"/>
                                                    </clipPath>
                                                </defs>
                                            </svg>

                                            <div className={styles.status_content_text}>
                                                <h1>
                                                    {lang_code === "ru" ? "При оплате возникла ошибка" :"Your payment has failed"}
                                                </h1>
                                                <p>{lang_code === "en" ? "Вы можете попробовать еще раз, или связаться с поддержкой": "You can retry the payment below to continue this"}</p>
                                            </div>
                                        </div>
                                        <div style={{display: "flex", alignItems: "center", flexDirection: "column", gap: "10px", width: '100%'}}>
                                            <button style={{backgroundColor: '#232426', color: 'white'}} onClick={() => {
                                                TG_WEB_APP.openTelegramLink('https://t.me/DeGuardVPN_support')
                                            }}>{lang_code === "ru" ? "Поддержка" : "Contact support"}</button>
                                            <button style={{color: 'black'}} onClick={() => {
                                                PaymnetStore.setPaymentStatus(null)
                                            }}>{lang_code === "ru" ? "Попробовать снова" : "Try again"}</button>
                                        </div>
                                    </>
                                )
                        }


                    </div>
                    {/*<button>{UserStore.langCode === 'ru' ? 'Посмотреть детали оплаты' : 'View details'}</button>*/}
                </div>
            }
        </>
    )
}
