import React, {useEffect, useState} from 'react'
import {BuyPlan, VpnCard} from '../components/VpnPlanCards/PassCard/BuyPlan';
import {PushWalletConnect} from '../components/Notification/PushWalletConnect';
import {VpnActive} from "../components/VpnActive/VpnActive";
import Lottie from "lottie-react";
import buyPlanAnimation from "../components/Lottie/buyPlanAnimation.json";
import {observer} from "mobx-react-lite";
import UserStore from "../store/user-store";
import PaymnetStore from "../store/paymnet-store";
import style from "../components/Payments/Payments.module.css";
import Timer from "../components/Payments/Timer/Timer";
import {pay_secure, payRubSecureSvg, paySecureSvg, payUSDSecureSvg} from "../components/Icons/svg/paymnetSvgs";
import {PaymentStatus} from "../components/Payments/PaymentStatus/PaymentStatus";


export const Vpnpage = observer((props) => {
    // const TG_WEB_APP = window.Telegram.WebApp
    // const plan_data_nomis = {
    //     1: {
    //         "img_src": "/images/nomis_plans_img/pass.png",
    //         "sale_amount": 20,
    //         "plan_name": "Unlimited NFT Pass",
    //         "plan_price_usd": 5000,
    //         "plan_id": 6,
    //         "plan_features": [["/images/plans_icons/lifetime.svg", "Lifetime access ", "to DeGuard VPN"], ["/images/plans_icons/bandwidth.svg", "Unlimited bandwidth", "on 5 devices"], ["/images/plans_icons/protocols.svg", "Wireguard and Vless", "support"], ["/images/plans_icons/priorityaccess.svg", "Priority Access", "to the upcoming IDO"], ["/images/plans_icons/dgp.svg", "+200.000 $DF", "points per day"],]
    //     }, 2: {
    //         "img_src": "/images/nomis_plans_img/annual.png",
    //         "sale_amount": 30,
    //         "plan_name": "Annual plan",
    //         "plan_price_usd": 1500,
    //         "plan_id": 3,
    //         "plan_features": [["/images/plans_icons/bandwidth.svg", "Unlimited bandwidth", "on 3 devices"], ["/images/plans_icons/protocols.svg", "Wireguard and Vless", "support"], ["/images/plans_icons/dgp.svg", "+100.000 $DF", "points per day"],
    //
    //         ]
    //     }, 3: {
    //         "img_src": "/images/nomis_plans_img/monthly.png",
    //         "sale_amount": 50,
    //         "plan_name": "Monthly plan",
    //         "plan_price_usd": 250,
    //         "plan_id": 1,
    //         "plan_features": [["/images/plans_icons/bandwidth.svg", "Unlimited bandwidth", "on 51devices"], ["/images/plans_icons/protocols.svg", "Wireguard and Vless", "support"], ["/images/plans_icons/dgp.svg", "+50.000 $DF", "points per day"],]
    //     },
    // }
    // const [paymentStatus, setPaymnetStatus] = useState();
    // const [isPlanBuy, setIsPlanBuy] = useState();
    // const [isLoading, setIsLoading] = useState();
    // const [requestSent, setRequestSent] = useState(false); // Новое состояние для отслеживания отправки запроса


    if (PaymnetStore.paymentPush && !PaymnetStore.paymentStatus) {
        setTimeout(() => {
            PaymnetStore.setPaymentPush(false)
        }, 5000)
    }

    // console.log(UserStore.userPlanData)
    return (
        <div className='vpn'>
            {(PaymnetStore.paymentStatus && PaymnetStore.paymentMethod !== 4) ? <PaymentStatus/> :

                (UserStore.userPlanData ? (
                            <>
                                {PaymnetStore.paymentPush && <PushWalletConnect push_text={"Plan Purchased"}/>}
                                {PaymnetStore.paymentPush && <div className="centered-container_vpnpage">
                                    <Lottie
                                        className="awaiting_animation_vpnpage"
                                        animationData={buyPlanAnimation}
                                    ></Lottie>
                                </div>}
                                <VpnActive props={UserStore.userPlanData}/>

                            </>
                        ) :
                        <div className="vpn__heading">
                            <div className="vpn__heading__main">
                                {/*Платежные методы*/}
                                {
                                    PaymnetStore.isPaymentProcessed &&
                                    <div className={style.payment_process}>
                                        <div className={style.loader}></div>
                                        <button onClick={() => {
                                            PaymnetStore.setPaymentProcessed(false)
                                            PaymnetStore.resetTimer()
                                        }}>{UserStore.langCode === 'ru' ? 'Отменить оплату' : 'Cancel payment'}</button>
                                        {(PaymnetStore.paymentMethod === 1 || PaymnetStore.paymentMethod === 2) &&
                                            <Timer duration={10}/>}
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            gap: "10px",
                                            position: "absolute",
                                            bottom: "5px"
                                        }}>
                                            {PaymnetStore.paymentMethod === 1 && payRubSecureSvg}
                                            {PaymnetStore.paymentMethod === 2 && payUSDSecureSvg}
                                            <a href="https://deguard.io" rel="noreferrer" target="_blank">© 2022-2024
                                                DeGuard.io</a>
                                        </div>
                                    </div>
                                }
                                <BuyPlan/>
                            </div>
                        </div>
                )
            }
        </div>
    )
})

