import '../src/App.css';
import {Footer} from '../src/components/Footer/Footer';
import {Routes, Route, useLocation} from 'react-router-dom'
import {Homepage} from '../src/pages/Homepage';
import {Friendspage} from '../src/pages/Friendspage';
import {Taskspage} from '../src/pages/Taskspage';
import {Vpnpage} from '../src/pages/Vpnpage';
import Onboardingpage from './pages/Onboardingpage';
import {useEffect, useState} from 'react';
import {TonConnectUIProvider} from '@tonconnect/ui-react';
import Lottie from "lottie-react";
import processingAnimation from "./components/Lottie/processingAnimation.json";
import userStore from "./store/user-store";
import UserStore from "./store/user-store";
import {observer} from "mobx-react-lite";
import {usePartnerList} from "./Hooks/Api/usePartnerList";
import {increasePartnerCounter} from "./Hooks/Api/increasePartnerCounter";

const App = observer(() => {
    const TG_WEB_APP = window.Telegram.WebApp
    const [isOnboarding, setOnboarding] = useState(localStorage.getItem('onboarding'))
    const [userId, setUserId] = useState();
    const [userPlanData, setUserPlanData] = useState(localStorage.getItem('plan_data'));
    const [tgStartParam, setStartParam] = useState(window.Telegram.WebApp.initDataUnsafe?.start_param);
    const [telegramUserId, setTelegramUserId] = useState(null);
    const [tg_data, setTgtData] = useState();
    const [isPlanDataFetching, setIsPlanDataFetching] = useState(true);
    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        TG_WEB_APP.setHeaderColor('#000000');
        TG_WEB_APP.setBackgroundColor('#000000');
        // TG_WEB_APP.enableClosingConfirmation();
        TG_WEB_APP.ready();
        TG_WEB_APP.disableVerticalSwipes();
        TG_WEB_APP.expand();
    }, [])

    const changePlanDataHandler = (data) => {
        setUserPlanData(data)
        // console.log(userPlanData)
    }

    const HEADERS = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
    }

    const telegramData = {
        id: String(TG_WEB_APP.initDataUnsafe.user?.id),
        first_name: TG_WEB_APP.initDataUnsafe.user?.first_name,
        last_name: TG_WEB_APP.initDataUnsafe.user?.last_name,
        username: TG_WEB_APP.initDataUnsafe.user?.username,
        language_code: TG_WEB_APP.initDataUnsafe.user?.language_code

        // id: '349577070',
        // first_name: "Mikhail",
        // last_name: "Aleksin",
        // username: "mrxnzxt",
        // language_code: "ru"
    }
    userStore.setLangCode(telegramData?.language_code)


    const {data: partnerData, isLoading: isPartnersLoading} =  usePartnerList(tgStartParam)

    useEffect(() => {
        if (tgStartParam && partnerData) {
            const target_partner = Object.entries(partnerData).filter((el) => el[1] === tgStartParam)
            if (target_partner.length > 0) {
                UserStore.setPartner(target_partner[0].shift())
                increasePartnerCounter()
            }


        }
    }, [partnerData, tgStartParam, isPartnersLoading]);

    // console.log(UserStore.partnerId)
    useEffect(() => {

        switch (tgStartParam) {
            case 'yumify':
                UserStore.setPartner(2)
                break;
            case 'spheroid_universe':
                UserStore.setPartner(4)
                break;
            case 'steamify':
                UserStore.setPartner(5)
                break;
            case 'secret_pad':
                UserStore.setPartner(6)
                break;
            case 'dragon':
                UserStore.setPartner(7)
                break;
            case 'mole':
                UserStore.setPartner(8)
                break;
            default:
        }
    }, [tgStartParam])

    const getPlanData = (innerUserId) => {
        setIsPlanDataFetching(true)
        fetch(`${apiUrl}/plans/${innerUserId}`, {
            method: 'GET',
            headers: HEADERS
        }).then(res => {
                if (res.status === 400) {
                    setUserPlanData(false)
                    setIsPlanDataFetching(false)
                } else {
                    res.json().then(res => {
                        setUserPlanData(res)
                        userStore.setUserPlanData(res)
                        setIsPlanDataFetching(false)
                    })
                }
            }
        ).catch(res => {
            localStorage.removeItem("plan_data")
            setIsPlanDataFetching(false)
        })
    }


    useEffect(() => {
        const fetchData = () => {
            fetch(`${apiUrl}/miniapp/get_user_id/${telegramData.id}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
                    }
                }
            ).then((res) => {
                res.json().then((res) => {
                    if (res !== false) {
                        userStore.setUserId(res.user_id)
                        setUserId(res.user_id)
                        getPlanData(res.user_id);
                    } else {
                        fetch(`${apiUrl}/user/create/telegram_user`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
                            },
                            body: JSON.stringify(telegramData)
                        }).then(res => res.json().then(res => {
                            if (res.user_data) {
                                userStore.setUserId(res.user_data.user_id)
                                setUserId(res.user_data.user_id);
                                getPlanData(res.user_data.user_id);
                                localStorage.setItem('user_id', res.user_data.user_id);
                            }
                        })).catch(res => console.log(res))
                    }
                })
            })
        }
        fetch(`${apiUrl}/servers/available_servers`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
                },
            })
            .then(res => res.json()
                .then(res => {
                    localStorage.setItem("servers", JSON.stringify(res))
                })
                .catch(err => console.log(err)))

        fetchData();


    }, []);

    useEffect(() => {
        if (window.Telegram.WebApp.initDataUnsafe.start_param && !localStorage.getItem('isIncreaseBanner')) {
            fetch(`${apiUrl}/miniapp/increase_counter`, {
                method: 'POST',
                headers: HEADERS,
                body: JSON.stringify({"source": window.Telegram.WebApp.initDataUnsafe.start_param})
            })
                .then(res => res.json()
                    .then(res => {
                        localStorage.setItem('isIncreaseBanner', "true")
                    }))
                .catch(err => console.log(err))
        }
    }, [])


    const location = useLocation();

    const [displayLocation, setDisplayLocation] = useState(location);
    const [transitionStage, setTransistionStage] = useState("fadeIn");

    useEffect(() => {
        if (location !== displayLocation) setTransistionStage("fadeOut");
    }, [location, displayLocation]);


    if (process.env.REACT_APP_STAND === 'DEV' && !(['349577070','2200829547', '785023632', '426000871', '6928492759', '1534268906', '7039771033','7850236321'].find(el => el === telegramData.id))) {
        return <h1 style={{textAlign: 'center'}}>Sorry, you don't have access</h1>;
    }
    // isPlanDataFetching, setIsPlanDataFetching
    return (
        <>
            {!UserStore.userId || isPlanDataFetching || isPartnersLoading ?
                <div className="centered-container">
                    <Lottie
                        className="awaiting_animation"
                        animationData={processingAnimation}
                    ></Lottie>
                </div> : <TonConnectUIProvider
                    manifestUrl="https://app.deguard.io/ton/tonconnect-manifest.json"
                    actionsConfiguration={{
                        twaReturnUrl: process.env.REACT_APP_TON_REDIRECT
                    }}
                >
                    <div className={`app page ${transitionStage}`}
                         onAnimationEnd={() => {
                             if (transitionStage === "fadeOut") {
                                 setTransistionStage("fadeIn");
                                 setDisplayLocation(location);
                             }
                         }}>
                        {!isOnboarding ? <Onboardingpage setOnboarding={setOnboarding}/>
                            : (
                                <>
                                    <Routes location={displayLocation}>
                                        <Route path='/' element={<Homepage plan_data={userPlanData}
                                                                           planHandler={changePlanDataHandler}
                                        />}/>
                                        <Route path='/vpn' element={<Vpnpage plan_data={userPlanData}
                                                                             planHandler={changePlanDataHandler}
                                        />}/>
                                        <Route path='/tasks' element={<Taskspage/>}/>
                                        <Route path='/friends' element={<Friendspage/>}/>
                                    </Routes>

                                </>
                            )
                        }
                    </div>
                    {isOnboarding && <Footer/>}
                </TonConnectUIProvider>}
        </>
    )
})


export default App;

