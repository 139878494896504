import React from "react";
import DeGuardIcon from "../Icons/DeGuardIcon";
import UserStore from "../../store/user-store";

export const Welcomecard = () => {
  return (
    <>
      <div className="onboarding__main_content__logo">
        <DeGuardIcon />

            {UserStore.langCode === 'ru' ? <h1>Добро пожаловать <br/> в DeGuard</h1> :<h1> Welcome to <br /> DeGuard VPN</h1>}

      </div>


        {UserStore.langCode === 'ru' ?
            <>
                <p>Приватный и быстрый VPN, <br/> работающий из любой точки мира. </p>
                <p>Более 30 серверов с 99% uptime.</p>
                <p>Гибкий выбор протоколов и <br/>платежных методов.</p>
            </>
            :
            <p>Here you can earn points <br/> from our ecosystem</p>}

    </>
  );
};
