import React from 'react'
import style from "./PushWalletConnect.module.css"
import PaymnetStore from "../../store/paymnet-store";

export const PushWalletConnect = (props) => {
    return (
        <div className={style.push_wallet_connect}>

            {
                props.push_image ?
                    props.push_image
                    :
                    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M9.5 0C4.52944 0 0.5 4.02944 0.5 9C0.5 13.9706 4.52944 18 9.5 18C14.4706 18 18.5 13.9706 18.5 9C18.5 4.02944 14.4706 0 9.5 0ZM8.83305 13.1039L14.3 6.075L13.175 5.2L8.66546 10.998L5.67245 8.20451L4.7 9.24642L8.83305 13.1039Z"
                              fill="white"/>
                    </svg>}
            <p>{props.push_text}</p>
        </div>
    )
}
