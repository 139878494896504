import axios from "axios";
import UserStore from "../../store/user-store";

const apiUrl = process.env.REACT_APP_API_URL;

export const increasePartnerCounter = async () => {
    await axios.post(`${apiUrl}/v2/miniapp/partner_counter/${UserStore.partnerId}`, {}, {
        headers: {
            Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`
        }
    })
}